<template>
  <v-app>
    <div v-if="loading">
      <v-progress-linear indeterminate color="primary"/>
    </div>
    <component :is="layout" :menu-items="menuItems" :logo="logo" v-if="show">
      <router-view :key="key"></router-view>
    </component>

    <v-snackbar
      v-for="notification in notifications"
      :color="notification.color"
      :timeout="notification.timeout"
      :key="notification.id"
      :value="notification.message"
      @input="() => clearNotification(notification)"
    >
      {{ notification.message }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Default from '@/layouts/Default';
import NoSidebar from '@/layouts/NoSidebar';

const default_layout = 'default';

export default {
  components: {
    Default,
    NoSidebar,
  },
  computed: {
    ...mapGetters('auth', [ 'company', 'expires', 'menuItems', 'user', 'userId' ]),
    isProposals() {
      return window.location.host.split('.')[0] === 'proposals';
    },
    logo() {
      return this.company.logo || [ 'https://we.curate.co/hubfs/curate%20logo-2.png', 120, 120, true ];
    },
    authenticated() {
      return (!!this.user && !!this.expires && this.expires > (new Date()).getTime()) || !this.isProposals;
    },
    layout() {
      return (this.$route.meta.layout || default_layout);
    },
    notifications() {
      return this.$store.state.notifications;
    },
    loading() {
      return this.$store.state.loading;
    },
    show() {
      // wait for the route to have a name so that
      // we don't see the default layout before the
      // route meta exists and sets the correct layout
      return !!this.$route.name;
    },
    key() {
      return this.$route.meta.key || this.$route.fullPath;
    },
  },
  methods: {
    clearNotification(notification) {
      this.$store.commit('clearNotification', notification.id);
    },
  },
  watch: {
    userId(val) {
      this.$optimizely.userId = val;
    },
  },
};
</script>

<style>
#app {
  width: 100%;
}
</style>
