import apolloProvider from '../vue-apollo';
import SUBSCRIPTION_LIMIT from '../graphql/Companies/SubscriptionLimit.gql';

const goToLoginPage = () => {
  localStorage.removeItem('curate_token');

  if (window.location.host.split('.')[0] !== 'proposals') {
    const auth_url = process.env.VUE_APP_AUTH_LOGIN_URL;
    window.location.href = `${auth_url}?redirect_to=${encodeURIComponent(window.location.href)}`;
  }
};

export default {
  namespaced: true,
  state: {
    company: { },
    expires: null,
    menuItems: [ ],
    user: null,
    permissions: {
      // These should be kept in sync with the default permissions in the old
      // app in Permissions.php
      'marketplace': true,
      'marketplace.resources.purchase': true,
      'marketplace.resources.view': true,
      'settings': true,
      'settings.billing': true,
      'settings.users': true,
      'settings.users.add_seats': true,
      'proposal.item.description': true,
      'proposal.item.photos': true,
    },
    limits: {},
  },
  getters: {
    company: state => state.company,
    expires: state => state.expires,
    menuItems: state => state.menuItems,
    permissions: state => state.permissions,
    token: state => state.token,
    user: state => state.user,
    userId: state =>  !state.user ? null : state.user.id,
    limits: state => state.limits,
  },
  mutations: {
    setCompany: (state, company) => state.company = company,
    setExpires: (state, expires) => state.expires = expires,
    setMenuItems: (state, menuItems) => state.menuItems = menuItems,
    setPermissions: (state, permissions) => state.permissions = permissions,
    setToken: (state, token) => state.token = token,
    setUser: (state, user) => state.user = user,
    setLimits: (state, limits) => state.limits = limits,
  },
  actions: {
    authenticate({ commit, getters, dispatch }, newUser = false) {
      if (getters.token) return;

      const auth_endpoint = process.env.VUE_APP_AUTH_ENDPOINT;

      return fetch(auth_endpoint, {
        credentials: 'include',
      }).then(r => r.json()).then(json => {
        if (('error' in json && json.error === 'Unauthenticated') || (!('token' in json))) {
          goToLoginPage();
        } else {
          return new Promise(resolve => {
            const currentSlug = window.location.host.split('.')[0];
            const currentEnv = window.location.host.split('.')[1];
            const token = json.token;
            const encoded = token.split('.')[1];
            const content = JSON.parse(atob(encoded.replace(/-/g, '+').replace(/_/g, '/')));

            const permissions = json.permissions;
            const expires = content.exp;
            const menuItems = json.menu.filter(item => (item.permission === '' || !!permissions[item.permission]));

            commit('setUser', content.user);
            commit('setCompany', content.company);
            commit('setToken', token);
            commit('setExpires', expires);
            commit('setMenuItems', menuItems);
            commit('setPermissions', permissions);

            if (expires <= (new Date()).getTime()) {
              goToLoginPage();
            }

            if (currentSlug !== content.company.slug && !newUser && currentEnv !== 'local') {
              // You are currently logged in to a different subdomain. Remove the token on this subdomain and redirect to the correct location.
              localStorage.removeItem('curate_token');
              window.location.href = window.location.href.replace(currentSlug, content.company.slug);
              return resolve();
            }

            localStorage.setItem('curate_token', token);
            dispatch('getLimits');
            resolve();
          });
        }

      });
    },
    getLimits({ commit, getters }) {
      apolloProvider.defaultClient.query({
        query: SUBSCRIPTION_LIMIT,
        variables: {
          company_id: getters.company.id,
        },
      }).then(data => {
        commit('setLimits', data.data.limits);
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  modules: {
  },
};
